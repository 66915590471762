<template>
  <div class="orga" v-if="currentRoute">
    <stage class="orga__stage" :stageLabel="$i18n.locale === 'de' ? 'Organisationsstruktur' : 'Organization structure'" :title="currentRoute.title.rendered"/>
    <div class="orga__content" v-if="currentRoute.acf.stageImage.url">
      <div class="orga__image-wrapper">
        <img class="orga__image" :src="currentRoute.acf.stageImage.url"/>
      </div>
    </div>
    <introduction
      v-if="currentRoute.acf.statement"
      class="orga__intro"
      fontWeight="bold"
      :subline="$i18n.locale === 'de' ? 'Organisationsstruktur' : 'Organization structure'"
      :maintext="currentRoute.acf.statement"
    />
    <introduction
      v-if="currentRoute.acf.description"
      class="orga__intro text"
      fontWeight="normal"
      :maintext="currentRoute.acf.description"
    />
    <div v-if="currentRoute.acf.blocks.length" class="orga__section-bg">
        <h3 class="orga__sectionhead">{{currentRoute.acf.more}}</h3>
        <inline-blocks :withMargin="false" :blocks="currentRoute.acf.blocks"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/introduction.vue'
import InlineBlocks from '@/components/inline-blocks.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Organisationsstruktur',
  components: {
    Stage,
    Introduction,
    InlineBlocks
  },
  computed: {
    ...mapGetters([
        'getFetched'
    ]),
    currentRoute(){
      const getFetched =  this.getFetched[this.$route.name];
      return getFetched;
    },
  },
  created() {
      if(!this.currentRoute) {
          this.fetchCurrentPage(this.$route.name);
      }
  },
  methods: {
    ...mapActions([
          'fetchCurrentPage',
    ]),
  }
}
</script>
<style lang="scss" scoped>
.orga {
  margin-top: 86px;
  padding: 0 .8rem;
  &__content {
    max-width: 1140px;
    margin: auto;
    padding:  0 .8rem;
  }
  &__stage {
    margin-bottom: 1.6rem;
      @media (min-width: 1024px) {
        margin-bottom: 150px;
      }
  }
  &__sectionhead {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family:"neue-plak";
    font-weight: 500;
    color: #182952;
    margin-bottom: 65px;
  }
  &__note {
    font-size: 15px;
    font-family: 'freight-text-pro';
    margin-left: .4rem;
    color: #666666;
    text-decoration: none;
  }
  &__sectionhead {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-family:"neue-plak";
      font-weight: 500;
      color: #182952;
      margin-bottom: 65px;
  }
  &__section-bg {
      padding:30px 0;
      max-width: 1140px;
      margin: auto;
      background-color: #e8edf8;
      position: relative;
      margin-bottom: 1.6rem;
      &:after {
          content: '';
          position: absolute;
          width: 100vw;
          left: calc(50% - 50vw);
          top: 0;
          z-index: -1;
          height: 100%;
          background-color: #e8edf8;
      }
  }
  &__intro {
    &:not(.text) {
      margin-bottom: 0;
    }
  }
  &__image-wrapper {
    margin-bottom: 150px;
    max-width: 927px;
    width: 100%;
  }
  &__image {
    margin: auto;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
</style>
